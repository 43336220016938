/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "@esri/arcgis-html-sanitizer";
import t from "../../core/ArrayPool.js";
import { handlesGroup as n, makeHandle as r } from "../../core/handleUtils.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { watch as i, syncAndInitial as o } from "../../core/reactiveUtils.js";
import { clearVNodeCache as s } from "./vnodeCache.js";
var a,
  c,
  l = function (e) {
    if ("WebkitTransition" in e.style) a = "webkitTransitionEnd", c = "webkitAnimationEnd";else {
      if (!("transition" in e.style)) throw new Error("Your browser is not supported!");
      a = "transitionend", c = "animationend";
    }
  },
  d = function (e) {
    a || l(e);
  },
  u = function (e, t) {
    return void 0 === t && (t = e + "-active"), function (n) {
      d(n);
      var r = !1,
        i = function (o) {
          r || (r = !0, n.removeEventListener(a, i), n.removeEventListener(c, i), n.classList.remove(e), n.classList.remove(t));
        };
      n.classList.add(e), n.addEventListener(a, i), n.addEventListener(c, i), requestAnimationFrame(function () {
        n.classList.add(t);
      });
    };
  },
  p = function (e, t) {
    return void 0 === t && (t = e + "-active"), function (n, r) {
      d(n);
      var i = !1,
        o = function (e) {
          i || (i = !0, n.removeEventListener(a, o), n.removeEventListener(c, o), r());
        };
      n.classList.add(e), n.addEventListener(a, o), n.addEventListener(c, o), requestAnimationFrame(function () {
        n.classList.add(t);
      });
    };
  };
function f(...e) {
  const n = t.acquire();
  for (let t = 0; t < e.length; t++) {
    const r = e[t],
      i = typeof r;
    if ("string" === i) n.push(r);else if (Array.isArray(r)) n.push.apply(n, r);else if ("object" === i) for (const e in r) r[e] && n.push(e);
  }
  const r = n.join(" ");
  return t.release(n), r;
}
const m = (() => {
  const e = new Map(),
    t = new ResizeObserver(t => {
      s();
      for (const n of t) e.get(n.target)?.(n);
    }),
    a = (n, i, o) => (e.set(n, i), t.observe(n, o), r(() => {
      t.unobserve(n), e.delete(n);
    }));
  return (e, t, s) => {
    let c = null;
    return n([i(() => "function" == typeof e ? e() : e, e => {
      c?.remove(), e && (c = a(e, t, s));
    }, o), r(() => c?.remove())]);
  };
})();
function g(e) {
  const t = e?.closest("[dir]");
  return null !== t && t instanceof HTMLElement && "rtl" === t.dir || "rtl" === document.dir;
}
function h(e) {
  return g(e) ? "rtl" : "ltr";
}
function v(e) {
  const t = "data-node-ref";
  this[e.getAttribute(t)] = null;
}
function b(e) {
  const t = "data-node-ref";
  this[e.getAttribute(t)] = e;
}
function L(e, t) {
  return ("enter" === e ? u : p)(t);
}
const w = ["dd", "dl", "dt", "h1", "h2", "h3", "h4", "h5", "h6", "sub", "sup", ...["animate", "animatetransform", "circle", "clippath", "defs", "ellipse", "g", "image", "line", "lineargradient", "marker", "mask", "path", "pattern", "polygon", "polyline", "radialgradient", "rect", "stop", "svg", "switch", "symbol", "text", "textpath", "tspan", "use"]],
  y = w.reduce((e, t) => (e[t] = [], e), {}),
  E = ["align", "alink", "alt", "bgcolor", "border", "cellpadding", "cellspacing", "class", "color", "cols", "colspan", "coords", "d", "dir", "face", "height", "hspace", "ismap", "lang", "marginheight", "marginwidth", "multiple", "nohref", "noresize", "noshade", "nowrap", "ref", "rel", "rev", "rows", "rowspan", "scrolling", "shape", "span", "summary", "tabindex", "title", "usemap", "valign", "value", "vlink", "vspace", "width"],
  j = new e({
    whiteList: y,
    onTagAttr: (e, t, n) => {
      const r = `${t}="${n}"`;
      if (E.includes(t)) return r;
    },
    stripIgnoreTag: !0,
    stripIgnoreTagBody: ["script", "style"]
  }, !0);
function A(e, t) {
  const n = e.getBoundingClientRect(),
    r = t.getBoundingClientRect(),
    i = n.top + n.height,
    o = r.top + r.height,
    s = n.top,
    a = r.top;
  (i > o || s < a) && e.scrollIntoView({
    block: "end"
  });
}
function k(e) {
  return "Enter" === e || " " === e;
}
function T(e) {
  return Object.values(e).includes(!0);
}
export { w as additionalAllowedTags, f as classes, L as cssTransition, v as discardNode, h as getDir, k as isActivationKey, g as isRTL, A as keepMenuItemWithinView, m as onResize, j as renderingSanitizer, E as safeAttrs, T as someElementsAreVisible, b as storeNode };